define("lbp-cust-web-application/routes/ticust-web-module-authentication/sso-dispatch", ["exports", "ti-web-shared/mixins/toastMixin"], function (_exports, _toastMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_toastMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;
      this._super(transition);
      var transferredData = transition.queryParams.data.split('__');
      var data = {
        code: transition.queryParams.code,
        username: transferredData[0],
        password: '',
        company: transferredData[1]
      };
      return this.session.authenticate('authenticator:ti', data).then(function () {
        _this.transitionTo('app');
      }).catch(function (error) {
        _this.transitionTo('login');
        _this.showErrorAndLog('authentication.sso-error-dispatch', 'An issue occurred while dispatching sso user', error);
      });
    }
  });
  _exports.default = _default;
});