define("lbp-cust-web-application/initializers/components/ticust-web-module-interactions/conversation/conversation-message-attachment", ["exports", "ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-attachment", "lodash", "ember-uuid"], function (_exports, _conversationMessageAttachment, _lodash, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // eslint-disable-line

  function initialize() {}
  var _default = {
    name: 'ticust-web-module-interactions/components/ticust-web-module-interactions/conversation/conversation-message-attachment',
    // eslint-disable-line
    initialize: initialize
  };
  _exports.default = _default;
  _conversationMessageAttachment.default.reopen({
    displayAttachmentId: false,
    classNames: ['message-attachment', 'col-xs-12', 'col-md-4', 'attachments-list'],
    _isPopupOpened: false,
    contentType: undefined,
    originalFocusId: undefined,
    attachementFromLBP: Ember.computed('fromMe', function () {
      return !this.get('fromMe');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modalPreviewId', 'popup-preview-file-' + _emberUuid.default.v4());
      this.set('modalPreviewTitleId', 'popup-preview-file-' + _emberUuid.default.v4());
    },
    openAttachmentPopUp: Ember.computed('attachment', function () {
      if (this.get('displayAttachmentId')) {
        return "openAttachmentPopUpFor" + this.get('attachment.s3Id') + _emberUuid.default.v4();
      }
      return undefined;
    }),
    downloadFromS3: function downloadFromS3() {
      var _this = this;
      var params = {
        fileName: this.get('filename')
      };
      if (this.get('message.conversation.type') === 'CAMPAIGN') {
        params.conversationId = this.get('message.conversation.id');
      }
      var url = '';
      // LBP Override - Add redirect to template api when coming from campaign
      if (this.get('isCampaign') === true) {
        url = '/api/campaign-message-templates-attachments/' + this.get('contentReference');
      } else {
        url = this.get('ajaxNamespace') + '/conversation-message-attachments/' + this.get('contentReference');
      }
      var headers = _lodash.default.clone(this.get('identity.ajaxHeaders'));
      // Fix Accept headers to pdf and json to make things work with campaign service
      headers.Accept = ['application/pdf', 'application/json'];
      this.get('ajaxBinary').sendAjaxForBinary('GET', headers, url, params).catch(function (error) {
        _this.showErrorAndLog('interactions.message-attachment.message-info.error.download', 'An error occurred while downloading the attachment.', error);
      });
    },
    isCampaign: Ember.computed('message.type', function () {
      return this.get('message.type') === 'CAMPAIGN';
    }),
    showCloseButton: Ember.computed('filename', function () {
      if (this.get('filename').endsWith('.pdf')) {
        return true;
      }
      return false;
    }),
    // Get authors display name
    fromDisplay: Ember.computed('fromParticipants', 'attachementFromLBP', 'intl.locale', function () {
      if (this.get('attachementFromLBP')) {
        return this.get('intl').t('interactions.conversation-message-header.from.adviser');
      }
      return this.get('fromParticipants').map(function (participant) {
        return participant.displayName;
      }).join(', ');
    }),
    actions: {
      togglePopup: function togglePopup() {
        if (window.innerWidth < this.get('config.screenMd')) {
          this.send('preview');
        } else {
          this.toggleProperty('_isPopupOpened');
        }
        this.set('originalFocusId', this.get('openAttachmentPopUp'));
      },
      preview: function preview() {
        if (window.innerWidth < this.get('config.screenMd')) {
          if (this.get('contentType')[0].split(';')[0] !== 'application/pdf') {
            return this.showError('viewer.error.format');
          }
        }
        this.set('srcPreview', this.get('contentReference'));
        this.set('displayModal', true);
      }
    }
  });
});