define("lbp-cust-web-application/instance-initializers/override-router-ticust-web-module-authentication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(instance) {
    instance.register('route-map:ticust-web-module-authentication', function () {
      this.route('login');
      this.route('password', function () {
        this.route('reset');
        this.route('renew', {
          path: '/renew/:token'
        });
      });
      this.route('prospect', function () {
        this.route('create', {
          path: '/:company'
        });
        this.route('user', function () {
          this.route('create', {
            path: '/:id/:company'
          });
        });
      });
      // START custom route
      this.route('otu');
      this.route('sso-dispatch');
      this.route('sso-error');
      this.route('logout');
      // END of custom route
    });
  }
  var _default = {
    name: 'override-router-ticust-web-module-authentication',
    initialize: initialize
  };
  _exports.default = _default;
});